<template>
  <div id="app" class="min-h-screen overflow-x-hidden text-white bg-white dark:text-gray-200 dark:bg-black transition duration-200 ease-in-out">

    <Nav />
    <router-view />
    <Footer class="w-full border border-true-gray-300 dark:border-true-gray-900" />

  </div>
</template>
<script>

// @ is an alias to /src
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: 'App',
  components: {
    Nav,
    Footer,
  },
}
</script>
