<template>
  <div class="lg:max-w-6xl mx-auto">
    <div class="mb-12 mx-12 lg:mx-8 text-center pb-12 border-b border-gray-300 dark:border-gray-700">
      <h1 class="mb-4 text-4xl font-black text-black dark:text-true-gray-100">What I Use</h1>
      <h5 class="text-base lg:text-lg font-thin text-gray-700 dark:text-gray-400">
        Here is my list of different tools I use on my projects.
      </h5>
    </div>
    <div class="mx-12 lg:mx-8 text-left">
      <h5 class="font-bold text-gray-800 dark:text-gray-200 text-xl mb-2">My DevTools</h5>
      <p class="mb-3 text-gray-500 dark:text-gray-300 text-base font-thin tracking-tight">There are my development tools that I use.</p>
      <p class="text-gray-600 dark:text-gray-300 text-base tracking-tight"></p>
      <ul class="list-inside list-disc mt-4">
        <li class="text-blue-500 dark:text-blue-400 mb-1">
          VS Code <span class="text-gray-500 dark:text-gray-400 text-sm lg:text-base tracking-tighter font-thin font-xs md:font-sm"> as main code editor & for javascript projects.</span>
        </li>
        <li class="text-blue-500 dark:text-blue-400 mb-1">
          XCode <span class="text-gray-500 dark:text-gray-400 text-sm lg:text-base tracking-tighter font-thin font-xs md:font-sm"> for iOS projects.</span>
        </li>
        <li class="text-blue-500 dark:text-blue-400 mb-1">
          PHP Storm <span class="text-gray-500 dark:text-gray-400 text-sm lg:text-base tracking-tighter font-thin font-xs md:font-sm">as IDE for PHP (Laravel) projects.</span>
          </li>
        <li class="text-blue-500 dark:text-blue-400 mb-1">
          Postman <span class="text-gray-500 dark:text-gray-400 text-sm lg:text-base tracking-tighter font-thin font-xs md:font-sm">to test API's or trouble shoot them.</span>
        </li>
        <li class="text-blue-500 dark:text-blue-400 mb-1">
          MongoDB Compass <span class="text-gray-500 dark:text-gray-400 text-sm lg:text-base tracking-tighter font-thin font-xs md:font-sm">to connect to Mongo databases.</span>
        </li>
        <li class="text-blue-500 dark:text-blue-400 mb-1">
          Table Plus / DBeaver / MySQL Workbench <span class="text-gray-500 dark:text-gray-400 text-sm lg:text-base tracking-tighter font-thin font-xs md:font-sm">to connect to SQL databases.</span>
        </li>
        <li class="text-blue-500 dark:text-blue-400 mb-1">
          Oh My ZSH <span class="text-gray-500 dark:text-gray-400 text-sm lg:text-base tracking-tighter font-thin font-xs md:font-sm">as zsh shell framework.</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Tools',

}
</script>