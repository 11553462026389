<template>
  <div class="home mt-12 lg:mt-32">

    <PresentationHeroSection />

    <button type="button" class="block mt-20 mx-auto rounded-md p-1 outline-none focus:outline-none animate-bounce">
      <svg class="w-6 h-6 text-true-gray-800 dark:text-true-gray-100" fill="currentColor" viewBox="0 0 20 20">
        <path fill-rule="evenodd" d="M14.707 12.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 14.586V3a1 1 0 012 0v11.586l2.293-2.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
      </svg>
    </button>

    <SkillsSection class="mt-12" />

    <!--<Services class="mt-20"/>-->

    <Tools class="mt-32 mb-12" />
    
  </div>
</template>
<script>

import PresentationHeroSection from "@/components/home/PresentationHeroSection.vue";
import SkillsSection from "@/components/home/SkillsSection.vue";
import Tools from '../components/home/Tools.vue';
import Services from '../components/home/Services.vue';

export default {
  name: 'Home',
  components: {
    PresentationHeroSection,
    SkillsSection,
    Tools,
    Services,
  }
}
</script>
