import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Portfolio from "../views/Portfolio.vue";
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 
router.beforeEach((to, from, next) => {

  // set theme 
  if (localStorage.theme === 'dark' || ('theme' in localStorage &&
   window.matchMedia('(prefers-color-schema: dark)').matches)) { // dark is the current theme
    localStorage.setItem('theme', 'dark');
    document.querySelector('html').classList.add('dark');
    store.commit('toggleTheme', true);
  } else { // light is the current theme
    document.querySelector('html').classList.remove('dark');
    localStorage.removeItem('theme');
    store.commit('toggleTheme', false);
  }
  next();
})
 
export default router
