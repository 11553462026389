<template>
  <div>
    <div class="md:w-4/6 lg:max-w-6xl xl:max-w-5xl md:mt-16 mx-auto">
      <div class="xl:flex items-center">
        <div class="md:flex items-center justify-center xl:-mt-32">
          <div class="w-24 h-24 mx-auto md:mx-0 mb-1 p-1 bg-black dark:bg-white rounded-full">
            <div class="w-full h-full bg-center bg-cover rounded-full border-4 border-white dark:border-black"
            style="background-image: url('/img/me.JPEG')">
            </div>
          </div>
          <svg class="h-16 w-16 md:h-20 md:w-20 hidden md:inline-block text-true-gray-700 dark:text-gray-400" viewBox="0 0 24 24" width="32" height="32" stroke="currentColor" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round" fill="none" shape-rendering="geometricPrecision">
            <path d="M16.88 3.549L7.12 20.451"></path>
          </svg>
          <div class="mt-4 md:mt-0">
            <div class="text-center md:text-left text-2xl md:text-3xl xl:text-4xl font-medium text-black dark:text-gray-100">Armando Rodríguez</div>
            <div class="text-center md:text-left text-base md:text-lg xl:text-xl font-thin text-true-gray-500 dark:text-gray-400">Web Developer 🚀</div>
          </div>
        </div>
        <div class="mt-8 md:mt-6 mx-6 md:mx-0 lg:ml-auto">
          <div class="relative py-4 mx-auto">
            <div class="absolute inset-0 bg-gradient-to-r from-purple-400 dark:from-gray-800 to-indigo-600 dark:to-cool-gray-900 shadow-lg transform -skew-y-3 md:skew-y-0 md:-rotate-6 rounded-3xl transition duration-500 ease-in-out"></div>
            <div class="relative mx-auto px-8">
              <div class="inverse-toggle px-6 pt-5 shadow-lg text-gray-100 text-sm subpixel-antialiased bg-black dark:bg-gray-900 pb-6 rounded-lg leading-normal overflow-hidden">
                <div class="top mb-2 flex">
                  <div class="h-3 w-3 bg-red-500 dark:bg-red-500 rounded-full"></div>
                  <div class="ml-2 h-3 w-3 bg-yellow-300 dark:bg-yellow-300 rounded-full"></div>
                  <div class="ml-2 h-3 w-3 bg-green-400 dark:bg-green-500 rounded-full"></div>
                </div>
                <div class="mt-4 flex text-sm md:text-base h-12">
                  <span class="hidden md:inline-block xl:hidden text-purple-300 dark:text-purple-300">armando-lenovo-y50:~$</span>
                  <p class="flex-1 ml-2">
                    sudo apt-get <span class="italic">hire me</span> 🙋🏻‍♂️
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="xl:w-1/2 mt-20 md:mt-16 xl:-mt-12 lg:mr-auto mb-6 text-center xl:text-left font-light tracking-tight text-true-gray-600 dark:text-true-gray-400 text-lg lg:text-base px-6 lg:px-0">
        Software Developer, passionate about web/mobile development. I'm working as Web & React-Native developer at <a href="https://www.toditocash.com/" target="_blank" class="underline font-mono text-true-gray-800 dark:text-true-gray-200 subpixel-antialiased px-1">Todito</a> for the TI department. I've worked on different software projects using technologies like Laravel, Nodejs, Vuejs, SQL and No-SQL databases, React-Native among other technologies.
      </div>
      <div class="lg:flex lg:items-center justify-center xl:justify-start md:space-x-4 mx-12 md:mx-0 mt-20 xl:mt-2 text-center">
        <a href="https://www.linkedin.com/in/armandordz/" target="_blank" class="block text-center mb-5 lg:mb-0 px-6 py-4 lg:py-3 border border-transparent rounded-md text-base font-medium text-white bg-indigo-600 dark:bg-indigo-500 shadow-md-indigo hover:shadow-lg-indigo transition duration-300 ease-in-out">
          See my Linkedin
        </a>
        <a href="mailto:rodriguezhernandezarmando7@gmail.com" class="block text-center px-6 py-4 lg:py-3 border-2 border-black dark:border-white rounded-md shadow-sm text-base font-medium text-black dark:text-white bg-white dark:bg-black hover:text-white dark:hover:text-black hover:bg-black dark:hover:bg-white transition duration-300 ease-in-out">or Send me an E-Mail</a>
      </div>

      
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'PresentationHeroSection',
}
</script>