<template>
  <div class="max-w-6xl mx-auto">
    yee
  </div>  
</template>
<script>

export default {
  name: 'Services',

}
</script>
