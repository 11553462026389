const projects = [
  {
    title: 'Todito Cash App',
    miniTitle: 'Nov 2022 (at Todito)',
    imgSrc: '/img/portfolio/toditocash_app.png',
    links: [
      {
        type: 'appstore',
        url: 'https://itunes.apple.com/app/id1409896783',
        label: 'Download on App Store'
      },
      {
        type: 'playstore',
        url: 'https://play.google.com/store/apps/details?id=com.toditocash.tcm',
        label: 'Download on Play Store'
      },
    ],
    description: 'I rebuilt the entire ToditoCash (a wallet app) by 90% using mobile technologies like React Native, push notifications, Google Map API, geolocation, offline mode, device permissions, API REST communication through HTTP, card reader communication, among others. Also I collaborated to develop some API endpoints that included database queries. Todito is a Fintech company, so for obvious reasons I can\'t be very specific.',
    techs: [
      'React Native',
      'Client-Server architecture',
      'REST architecture',
      'Javascript',
      'SQL',
    ]
  },
  {
    title: 'Super Vale App',
    miniTitle: 'January 2022 (at Todito)',
    imgSrc: '/img/portfolio/supervale_app.png',
    links: [
      // {
      //   type: 'appstore',
      //   url: 'https://itunes.apple.com/app/id1409896783',
      //   label: 'Download on App Store'
      // },
      // {
      //   type: 'playstore',
      //   url: 'https://play.google.com/store/apps/details?id=com.toditocash.tcm',
      //   label: 'Download on Play Store'
      // },
    ],
    description: 'SuperVale is a Todito product that offers a debit card for meal vouchers, used by some municipal governments. I developed the application 100%, through which you can check your balance, card information, purchases, activate or deactivate your card among others features. This new app redesign is currently in the process of being launched to the market.',
    techs: [
      'React Native',
      'Dark mode',
      'UI design',
      'Node.js',
    ]
  },
  {
    title: 'Regia MediK Site & Laboratory Management Panel',
    miniTitle: 'May 2021 (at Valtre)',
    imgSrc: '/img/portfolio/regiamedik_site.png',
    links: [],
    description: 'I built multiple websites for each Regia Medi-K service including an admin panel for Regia MediK medical laboratory to manage patients, clinical analysis, analysis types, etc. I was involved on the full software life-cycle for this web applications. Projects mainly developed with Laravel v8 (PHP) and MySQL (SQL).',
    techs: [
      'Laravel framework (v8)',
      'platform/orchid package',
      'MySQL database',
      'Bootstrap & Tailwindcss'
    ]
  },
  {
    title: 'Food Delivery App',
    miniTitle: 'March 2021 (study project)',
    imgSrc: '/img/portfolio/food_delivery_app.png',
    links: [
      {
        type: 'url',
        url: 'https://youtu.be/nR6L5Q-X-w4',
        label: ''
      },
    ],
    description: 'A restaurant-focused React Native mobile app that offers food delivery services to customers. You can search for food, see categories, add food to cart, add food to favorites, manage your account, manage the cart and order to specific address.',
    techs: [
      'React Native',
      'React state management',
      'React Native navigation',
    ]
  },
  {
    title: 'Expenses & Incomes Web App',
    miniTitle: 'June 2020 (study project)',
    imgSrc: '/img/portfolio/expenses_incomes.png',
    links: [],
    description: 'An small web application to report monetary expenses and incomes to different registered accounts. Laravel as main backend framework, Lighthouse for serving GraphQL and create the API, Vuejs for client side and Tailwindcss as CSS framework.',
    techs: [
      'Laravel framework (v7)',
      'Vue framework (v2.6)',
      'MySQL database',
      'GraphQL w/ Passport and Lighthouse',
      'Tailwindcss'
    ]
  },
  {
    title: 'MembresíasFit',
    miniTitle: 'April 2020 (academic project)',
    imgSrc: '/img/portfolio/membresiasfit.png',
    links: [],
    description: 'Gym membership website to watch training sessions online. Website created with Wordpress CMS using plugins to manage memberships, memberships payments and modify appearance.',
    techs: [
      'Wordpress'
    ]
  },
  {
    title: 'E-commerce Network',
    miniTitle: 'July 2020 (university project)',
    imgSrc: '/img/portfolio/redcomercial.png',
    links: [],
    description: 'An e-commerce network to find stores, products or services. Visitors can request orders to stores and people that offer services. This project was developed with technologies like Laravel and MySQL to server side, bootstrap and some vue components to client side.',
    techs: [
      'Laravel framework (v7)',
      'MySQL database',
      'Bootstrap (v4)'
    ]
  },
]

export default projects
