import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// styles
import '../public/css/tw.dist.css'; // npm run tw:build
import './assets/css/portfolio.css';

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
