<template>
  <div class="w-screen">
    <div class="flex flex-items justify-between px-4 py-2 pt-5 md:py-8 md:px-12 lg:max-w-6xl md:mx-auto overflow-none">
      <router-link to="/" class="flex items-center cursor-pointer transition duration-300 group">
        <div class="flex items-center">
          <svg class="w-12 h-12 md:h-16 md:w-16 text-black dark:text-white" width="100%" height="100%" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5 4L16.9952 16.75H4.00481L10.5 4Z" fill="currentColor"/>
            <path d="M18.0027 15.9777L14.5694 9.99186L21.5023 10.0304L18.0027 15.9777Z" fill="currentColor"/>
          </svg>
        </div>
        <svg class="hidden md:inline-block text-true-gray-600 dark:text-true-gray-400" viewBox="0 0 24 24" width="32" height="32" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" shape-rendering="geometricPrecision">
          <path d="M16.88 3.549L7.12 20.451"></path>
        </svg>
        <span class="hidden md:inline-block font-light tracking-tight antialiased text-true-gray-600 dark:text-true-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-200 transition duration-300">Armando Rdz</span>
      </router-link>
      <div class="hidden md:flex md:items-center justify-center md:space-x-5">
        <router-link to="/home" active-class="text-gray-700" class="font-light tracking-tight text-true-gray-500 hover:text-gray-700 dark:hover:text-gray-300 dark:text-true-gray-400 border-b border-transparent hover:border-gray-normal transition duration-300">
          About Me
        </router-link>
        <router-link to="/portfolio" active-class="text-gray-700" class="font-light tracking-tight text-true-gray-500 hover:text-gray-700 dark:hover:text-gray-300 dark:text-true-gray-400 border-b border-transparent hover:border-gray-normal transition duration-300">
          Portfolio
        </router-link>
        <ToggleTheme :sm="true" class="ml-2" />
        <a :href="constants.CV_URL" target="_blank" active-class="border-gray-normal" class="font-normal text-white py-1.5 px-3 bg-indigo-600 dark:bg-indigo-500 dark:hover:bg-black dark:hover:text-white border border-transparent dark:hover:border-white border-2 rounded-lg transition duration-300 shadow-sm-indigo hover:shadow-lg-indigo">
          Download CV
        </a>
      </div>
      <div class="flex items-center justify-center md:hidden">
        <ToggleTheme class="mr-6" />
        <MobileMenu />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import MobileMenu from "@/components/nav/MobileMenu.vue";
import ToggleTheme from "@/components/nav/ToggleTheme.vue";
import constants from "../data/constants";

export default {
  name: 'Nav',
  components: {
    MobileMenu,
    ToggleTheme,
  },
  data () {
    return {
      constants,
    }
  },
  computed: {
    ...mapState({
      
    })
  },
  methods: {

  }
}
</script>
