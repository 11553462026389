<template>
  <div class="lg:max-w-8xl px-4 py-2 pt-5 md:py-8 md:px-12 md:mx-auto overflow-none">
    <div class="text-center md:w-1/2 md:mr-auto md:px-8 mx-auto">
      <h1 class="mb-2 text-4xl font-black text-black dark:text-true-gray-100">What I Know</h1>
      <h5 class="text-base lg:text-lg font-thin text-gray-700 dark:text-gray-400">
        Skills
      </h5>
    </div>
    <div class="grid gap-4 grid-cols-2 lg:grid-cols-3 mt-12">
      <div class="py-6 px-8 rounded bg-white dark:bg-black border border-gray-300 dark:border-gray-800 shadow-sm hover:border-gray-100 dark:hover:border-white hover:shadow-xl transition duration-300 ease-in-out">
        <h4 class="text-base lg:text-lg mb-3 font-medium text-gray-900 dark:text-gray-100">Nodejs framework</h4>
        <p class="text-sm lg:text-base font-thin text-gray-600 dark:text-gray-300">Building REST APIs and webapps with Express.js.</p>
      </div>
      <div class="py-6 px-8 rounded bg-white dark:bg-black border border-gray-300 dark:border-gray-800 shadow-sm hover:border-gray-100 dark:hover:border-white hover:shadow-xl transition duration-300 ease-in-out">
        <h4 class="text-base lg:text-lg mb-3 font-medium text-gray-900 dark:text-gray-100">Web frameworks</h4>
        <p class="text-sm lg:text-base font-thin text-gray-600 dark:text-gray-300">Like Vue.js, Laravel & React.</p>
      </div>
      <div class="py-6 px-8 rounded bg-white dark:bg-black border border-gray-300 dark:border-gray-800 shadow-sm hover:border-gray-100 dark:hover:border-white hover:shadow-xl transition duration-300 ease-in-out">
        <h4 class="text-base lg:text-lg mb-3 font-medium text-gray-900 dark:text-gray-100">HTML & CSS</h4>
        <p class="text-sm lg:text-base font-thin text-gray-600 dark:text-gray-300">Websites design with tailwindcss and bootstrap</p>
      </div>
      <div class="py-6 px-8 rounded bg-white dark:bg-black border border-gray-300 dark:border-gray-800 shadow-sm hover:border-gray-100 dark:hover:border-white hover:shadow-xl transition duration-300 ease-in-out">
        <h4 class="text-base lg:text-lg mb-3 font-medium text-gray-900 dark:text-gray-100">Mobile development</h4>
        <p class="text-sm lg:text-base font-thin text-gray-600 dark:text-gray-300">Using React Native, Quasar, Java (Android) & Swift (iOS).</p>
      </div>
      <div class="py-6 px-8 rounded bg-white dark:bg-black border border-gray-300 dark:border-gray-800 shadow-sm hover:border-gray-100 dark:hover:border-white hover:shadow-xl transition duration-300 ease-in-out">
        <h4 class="text-base lg:text-lg mb-3 font-medium text-gray-900 dark:text-gray-100">Databases</h4>
        <p class="text-sm lg:text-base font-thin text-gray-600 dark:text-gray-300">I've worked with MySQL, PostgreSQL, Oracle & MongoDB.</p>
      </div>
      <div class="py-6 px-8 rounded bg-white dark:bg-black border border-gray-300 dark:border-gray-800 shadow-sm hover:border-gray-100 dark:hover:border-white hover:shadow-xl transition duration-300 ease-in-out">
        <h4 class="text-base lg:text-lg mb-3 font-medium text-gray-900 dark:text-gray-100">Git <span class="text-xs lg:text-sm">(version-control)</span></h4>
        <p class="text-sm lg:text-base font-thin text-gray-600 dark:text-gray-300">Using version control systems like Git & GitLab.</p>
      </div>
    </div>

    <div class="mt-8 text-center text-gray-600 dark:text-gray-400 text-sm lg:text-sm mx-12 md:mx-0">
      <span class="font-bold">Tip</span>: you can see my projects with some of this technologies on my <a href="https://github.com/Armando99Rdz" class="text-blue-600 dark:text-blue-400 hover:underline">GitHub</a> 🚀.
    </div>

  </div>
</template>

<script>
export default {
  name: 'SkillsSection'
}
</script>
