<template>
  <div @click="changeTheme" class="p-1 text-true-gray-800 dark:text-true-gray-300 cursor-pointer hover:bg-true-gray-100 dark:hover:bg-true-gray-900 rounded-full transition duration-300 ease-in-out animate-pulse">
    <svg :class="this.sm ? ['w-5','h-5'] : ['h-8', 'w-8']" :fill="this.darkTheme ? 'currentColor' : 'none'" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"></path></svg>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'ToggleTheme',
  props: ['sm'],
  computed: {
    ...mapState({
      darkTheme: 'darkTheme',
    })
  }, 
  methods: {
    changeTheme() {
      const htmlClasses = document.querySelector('html').classList;
      if (localStorage.theme == 'dark') {
        htmlClasses.remove('dark');
        localStorage.removeItem('theme');
        this.$store.commit('toggleTheme', false);
      } else {
        htmlClasses.add('dark');
        localStorage.theme = 'dark';
        this.$store.commit('toggleTheme', true);
      }
    },
  }
}
</script>
