<template>
  <div @click="toggleNavMenu">
    <div class="mr-2 text-true-gray-800 dark:text-true-gray-300 cursor-pointer">
      <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
      </svg>
    </div>

    <transition name="nav-menu-fade">
      <div v-show="isOpenNavMenu" class="absolute top-0 inset-x-0 z-50 p-2 transition transform origin-top-right md:hidden">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-true-gray-900 divide-y-2 divide-gray-50">
          <div class="pt-5 pb-6 px-6">
            <div class="flex items-center justify-between">
              <router-link to="/home">
                <svg class="w-12 h-12 text-black dark:text-white" width="100%" height="100%" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.5 4L16.9952 16.75H4.00481L10.5 4Z" fill="currentColor"/>
                  <path d="M18.0027 15.9777L14.5694 9.99186L21.5023 10.0304L18.0027 15.9777Z" fill="currentColor"/>
                </svg>
              </router-link>
              <div class="-mr-2">
                <button type="button" class="bg-white dark:bg-true-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 dark:text-true-gray-300 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-true-gray-800 focus:outline-none outline-none">
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: x -->
                  <svg class="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            </div>
            <nav class="mt-6">
              <router-link to="/" class="p-3 flex items-center rounded-md hover:bg-true-gray-100 dark:hover:bg-true-gray-800 transition duration-300 ease-in-out">
                  <svg class="flex-shrink-0 h-8 w-8 text-indigo-600 dark:text-indigo-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-width="0" d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                  </svg>
                  <span class="ml-3 text-base font-medium text-gray-900 dark:text-true-gray-100">
                    About me
                  </span>
                </router-link>
                <router-link to="/portfolio" class="p-3 flex items-center rounded-md hover:bg-true-gray-100 dark:hover:bg-true-gray-800 transition duration-300 ease-in-out">
                  <svg class="flex-shrink-0 h-8 w-8 text-indigo-600 dark:text-indigo-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
                  </svg>
                  <span class="ml-3 text-base font-medium text-gray-900 dark:text-true-gray-100">
                    Portfolio
                  </span>
                </router-link>
                <a :href="constants.CV_URL" target="_blank" class="p-3 flex items-center rounded-md hover:bg-true-gray-100 dark:hover:bg-true-gray-800 transition duration-300 ease-in-out">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="flex-shrink-0 h-7 w-7 text-indigo-600 dark:text-indigo-400">
                    <path fill-rule="evenodd" d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zm5.845 17.03a.75.75 0 001.06 0l3-3a.75.75 0 10-1.06-1.06l-1.72 1.72V12a.75.75 0 00-1.5 0v4.19l-1.72-1.72a.75.75 0 00-1.06 1.06l3 3z" clip-rule="evenodd" />
                    <path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
                  </svg>
                  <span class="ml-4 text-base font-medium text-gray-900 dark:text-true-gray-100">
                    My CV
                  </span>
                </a>
            </nav>
            <div class="mt-5 pt-4 border-t border-true-gray-400 dark:border-true-gray-600">
              <div>
                <a href="https://www.linkedin.com/in/armandordz/" target="_blank" class="w-full flex items-center justify-center text-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-gradient-to-r from-indigo-600 to-purple-700 transition duration-300 ease-in-out">
                  My Linkedin
                </a>
                <p class="mt-6 text-center text-base font-medium text-gray-500 dark:text-true-gray-300">
                  Send me an
                  <a href="mailto:rodriguezhernandezarmando7@gmail.com" class="text-indigo-600 dark:text-indigo-400 dark:hover:text-purple-400 hover:text-indigo-500">
                    E-Mail
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    
  </div>
</template>
<script>
import { mapState } from 'vuex'
import constants from '../../data/constants';

export default {
  name: 'MobileMenu',
  computed: {
    ...mapState({
      isOpenNavMenu: 'isOpenNavMenu',
    })
  },
  data () {
    return {
      constants,
    }
  },
  methods: {
    toggleNavMenu() {
      this.$store.commit('toggleNavMenu');
    }
  }
}
</script>

<style scoped>
  .nav-menu-fade-enter-active, .nav-menu-fade-leave-active {
    transition: all .1s ease-in-out;
  }
  .nav-menu-fade-enter, .nav-menu-fade-leave-to {
    opacity: 0;
    transform: translateY(-12px);
  }
</style>
