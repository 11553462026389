<template>
  <div class="portfolio">
    <div class="lg:max-w-8xl mt-8 lg:mt-10 px-4 md:px-12 md:mx-auto overflow-none">
      <div class="text-center max-w-2xl mx-auto">
        <div class="mb-6 text-4xl font-black text-gray-900 dark:text-gray-50">My Work</div>
        <div class="mb-4 text-sm lg:text-base uppercase font-thin text-indigo-500 dark:text-indigo-400 tracking-widest">portfolio</div>
        <p class="text-sm lg:text-base font-thin text-gray-600 dark:text-gray-300">Here some of my projects during my learning.</p>
      </div>
    </div>
    <div class="">
      <Work />
    </div>
  </div>  
</template>
<script>
import Work from "@/components/portfolio/Work.vue";
export default {
  name: 'Portfolio',
  components: {
    Work,
  }
}
</script>
