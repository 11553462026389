<template>
  <div class="">
    <div v-for="(project, idx) in projects" :key="idx">
      <Project
        :title="project.title"
        :miniTitle="project.miniTitle"
        :description="project.description"
        :imgSrc="project.imgSrc"
        :techs="project.techs"
        :links="project.links"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Project from "./Project.vue";
import projects from '../../data/projects';

export default {
  name: 'Work',
  components: {
    Project,
  },
  data() {
    return {
      projects,
    }
  },
  computed: {
    ...mapState({
      darkTheme: 'darkTheme',
    }),
  },
  created() {
    console.log(this.darkTheme);
  }
}
</script>
