import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    darkTheme: true,
    isOpenNavMenu: false,
  },
  getters: {
    darkTheme: state => state.darkTheme,
    isOpenNavMenu: state => state.isOpenNavMenu,
  },
  mutations: {
    toggleTheme(state, dark) {
      state.darkTheme = dark;
    },
    toggleNavMenu(state) {
      state.isOpenNavMenu = !state.isOpenNavMenu;
    }
  },
  actions: {
  },
  modules: {
  }
})
